import { HeroCard } from 'designsystem/components/2-molecules/Card/HeroCard';
import { LinkFeature } from 'features/Modules/Link';

export const HeroCardFeature: React.FC<Content.HeroCard> = ({ content, settings, ...props }) => {
	const { heading, subHeading: headingSubtext, text, link, picture } = content?.properties ?? {};
	const { imageAspectRatio } = settings?.properties ?? {};

	const { image, altText } = picture?.[0]?.content?.properties ?? {};
	const altTextChecked = altText == '""' ? '' : altText;

	return (
		<HeroCard
			image={
				image && {
					...image,
					altText: altTextChecked,
				}
			}
			content={{
				heading,
				headingSubtext,
				text,
				link: link ? (
					<LinkFeature url={link.url} name={link.name} target={link.target} style="arrow" isCTALink={settings?.properties?.isCTALink} />
				) : undefined,
			}}
			{...settings?.properties}
			imageAspectRatio={imageAspectRatio?.length ? imageAspectRatio : undefined}
			{...props}
		/>
	);
};
